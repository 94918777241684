import React from "react"
import PropTypes from "prop-types"

// https://icons.getbootstrap.com
import BootstrapIcons from "../assets/img/icons/bootstrap-icons.svg"

export const Icon = ({ name, width, height, ...props }) => {
  return (
    <svg width={width} height={height} {...props}>
      <use xlinkHref={`${BootstrapIcons}#${name}`} />
    </svg>
  )
}

Icon.propTypes = {
  /**
   * What icon name to fetch and use, from https://icons.getbootstrap.com
   */
  name: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
}

Icon.defaultProps = {
  name: "shop",
  height: "24px",
  width: "24px",
  className: "",
}
