import React from "react"
import PropTypes from "prop-types"

/**
 * Primary UI component for user interaction
 */
import { Icon } from "./Icon"

export const Button = ({ icon, label, ...props }) => {
  return (
    <button type="button" className="btn btn-primary" {...props}>
      {icon ? (
        <Icon
          width="1.1rem"
          height="1.1rem"
          name={icon}
          className={"spacer-right"}
        ></Icon>
      ) : null}
      {label}
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Optional icon name
   */
  icon: PropTypes.string,
}

Button.defaultProps = {
  onClick: undefined,
  icon: null,
}
