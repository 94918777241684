import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
// import "../../main.scss";
import { Button } from "./Button"

// Modified from here https://stackoverflow.com/a/50980203

export const Modal = ({ button, details, onSave, open, ...props }) => {
  const [isOpen, setOpen] = useState(open)
  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  const handleClick = e => {
    if (e.target !== e.currentTarget) {
      return
    }
    toggleOpen()
  }

  const handleKeyPress = e => {
    if (e.key === "Escape") {
      setOpen(false)
    }
  }

  // Needed for onKeyDown to have any effect apparently?
  useEffect(() => {})

  const apply = () => {
    if (onSave !== null) {
      onSave()
    }
    toggleOpen()
  }

  const modalButton = (
    <Button
      label={button.label}
      icon={button.icon}
      class={button.class}
      onClick={toggleOpen}
    ></Button>
  )

  return (
    <div>
      {button !== false ? modalButton : null}

      <div
        class={isOpen ? "modal fade show" : "modal fade"}
        id="exampleModal"
        tabindex="-1"
        style={{ display: isOpen ? "block" : "none" }}
        onClick={handleClick}
        onKeyDown={handleKeyPress}
        role="button"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable "
          aria-labelledby="exampleModalLabel"
          aria-hidden={isOpen ? "none" : ""}
          aria-modal={isOpen ? "true" : ""}
          role="dialog"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {details.title}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggleOpen}
              ></button>
            </div>
            <div class="modal-body">{props.children}</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={toggleOpen}
              >
                {details.dismiss}
              </button>
              <button type="button" class="btn btn-success" onClick={apply}>
                {details.save}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen ? <div class="modal-backdrop fade show"></div> : null}
    </div>
  )
}

Modal.propTypes = {
  /**
   * Label for dropdown button
   */
  button: PropTypes.object,
  /**
   * Label for dropdown button
   */
  details: PropTypes.object,
  /**
   * Optional click handler
   */
  onSave: PropTypes.func,
}

Modal.defaultProps = {
  button: {
    label: "Filters",
    icon: null,
    class: "btn btn-danger",
  },
  details: {
    title: "Filters",
    dismiss: "Close",
    save: "Apply",
  },
  onSave: null,
}
