import React from "react"

import { Modal } from "../components/Modal"
import App from "./index"

function Welcome() {
  const redirect = function () {
    console.log("This is where I would register the app... IF I HAD A BACKEND")
    window.location.replace("/")
  }
  const details = {
    title: "You're on the beta list!",
    dismiss: "Cancel",
    save: "OK",
  }

  return (
    <div>
      <Modal button={false} details={details} onSave={redirect} open={true}>
        <p>
          Great, you're in! Keep an eye on your email to be notified when the
          beta is ready for you to try out.
        </p>
      </Modal>
      <App></App>
    </div>
  )
}
export default Welcome
